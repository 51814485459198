.bg-video,
.jarallax-img {
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.flip-x {
  transform: scale(-1, 1);
}

.flip-y {
  transform: scale(1, -1);
}

.flip-x.flip-y {
  transform: scale(-1, -1);
}

[src*="edge-blend.svg"] {
  z-index: 0;
  pointer-events: none;
}

.navbar-stuck .navbar-brand {
  max-width: 150px;
}

// #{header}.header {
//   background-color: rgba(255, 255, 255, .4);
//   backdrop-filter: blur(20px);
// }
